import Image from 'next/image'
import Link from 'next/link'
import { useMediaQuery } from 'react-responsive'
import Footer from '../components/Footer'
import Header from '../components/Header'
import styles from '../styles/404.module.scss'

export default function PageNotFound() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <>
      <header className={styles.header}>
        <Header mode="dark" />
      </header>
      <div className={styles.layout}>
        {!isTabletOrMobile ? (
          <Image
            src="/assets/404.svg"
            width={400}
            height={150}
            alt="404 image"
          ></Image>
        ) : (
          <Image
            src="/assets/404.svg"
            width={350}
            height={100}
            alt="404 image"
          ></Image>
        )}
        <div className={styles.not_found}>Page not found</div>
        <Link href="/" passHref>
          <div className={styles.back_row}>
            <Image
              src="/assets/arrowleft.svg"
              width={28}
              height={28}
              alt="Left arrow"
            ></Image>
            <div className={styles.back_text}>Back to homepage</div>
          </div>
        </Link>
      </div>
      <Footer landing={true} />
    </>
  )
}
